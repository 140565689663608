import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, ImagesUrl, Token, Currency, config,  familyCode} from '../component/include'
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const FamilyFees=()=>{
    
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [allFamily, setAllFamily]= useState([])
    const [newFamily, setNewFamily]= useState([])
    const [feecharge, setFeecharge] = useState([])
    const [studentFee, setStudentFee]=useState({
        familyName:[],
        feecharge:'',
        sumTotal:'',
        sumPaid:'',
        sumBalance:''
    });
   

    const [classResult, setClassResult]=useState([]);
    const [payment, setPayment] =  useState([]);

    const fetchFamily =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_family'
        axios.post(url, fd, config).then(result=>setAllFamily(result.data.filter(item=>item.code===familyCode)))
    }

    const fetchFeecharge =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
        axios.post(url, fd, config).then(result=>setFeecharge(result.data.filter(item=>item.status=='true')))
    }


const fetchPayment =()=>{
    setNotice({...notice, isLoading: true}) 
    
    var sql =" Select  sum((select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code)) as paid, fm.familyName, fm.code, sum(s.total) as total from  tbl_student_fees s, tbl_fees_charge f, tbl_family fm, tbl_students st  where f.code = s.feeCharge  and fm.code = st.family_name and st.code = s.studentCode and st.family_name ='"+familyCode+"' group by fm.code "
    
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	
    if(result.data.length!==0){

    
setPayment(result.data)

var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
            var option = result.data
    for(var i = 0; i < option.length; i++)
    {
        sumTotal = sumTotal + Number(option[i].total);
        sumPaid = sumPaid + Number(option[i].paid);
        sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
    }
    setStudentFee({...studentFee,
        sumTotal:FormatNumber(sumTotal),
        sumPaid:FormatNumber(sumPaid),
        sumBalance:FormatNumber(sumBalance)
    })
}else{
    setPayment([])
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchResult =()=>{
	setNotice({...notice, isLoading: true}) 
	
    var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code) as paid, st.studentName, st.mobile, st.admissionNumber, fm.familyName,  s.studentCode, fm.code, s.total from  tbl_student_fees s, tbl_fees_charge f, tbl_family fm, tbl_students st  where f.code = s.feeCharge  and fm.code = st.family_name and st.code = s.studentCode and fm.code ='"+familyCode+"'" ;

 if(studentFee.feecharge.length!==0){
        sql = sql + '  and s.feeCharge =   "'+studentFee.feecharge.value+'"'
    }
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{

setNewFamily(allFamily)
setClassResult(result.data)

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}

const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
 }



  const getTotal =(code)=>{

    var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
    var option = classResult.filter(item=>item.code===code)

for(var i = 0; i < option.length; i++)
{
sumTotal = sumTotal + Number(option[i].total);
sumPaid = sumPaid + Number(option[i].paid);
sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
}

return {sumTotal:FormatNumber(sumTotal), sumPaid:FormatNumber(sumPaid), sumBalance:FormatNumber(sumBalance)}
  }

    
  
  
  useEffect(()=>{ 
    fetchFamily()
    fetchFeecharge()
},[]);


const handleSearch=()=>{
    fetchResult()
    fetchPayment()
}

   return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Family Fees">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Family Fees</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Family Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-6">
    
        <div className="form-group">
        
        <label >Fee Charge </label>
        <Select  options={feecharge&&feecharge.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                                     })
                                } 
                                getOptionLabel={option =>`${option.session}  ${option.label}`}       
        onChange={handleSelect} name="feecharge" value={studentFee.feecharge}  /> 
                    </div>
			</section>


                <section className="col-md-4"><br/>
<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>

{classResult&&classResult.length!==0?
<div>
{newFamily&&newFamily.map((item, index)=>
<div className="card z-depth-0" key={index} id={item.code}><div  className="card-block">
	
{schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}  
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Details</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>Family Name: <b>{item.familyName}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>Fees</th>
                <th>Student Name</th>
                <th> Mobile</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                
                </tr>
            </thead>
            <tbody >

                    {classResult&&classResult.map((dat, id)=>dat.code===item.code?<tr key={id}>
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{dat.fees}</td>
                       
                   
					<td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{dat.studentName} {dat.admissionNumber}</td>
                    <td>{dat.mobile}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>:[])}
				</tbody>
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="3"><b>Total</b> </td>
                    <td ><b > {getTotal(item.code).sumTotal}</b></td>
                    <td ><b >{getTotal(item.code).sumPaid}</b></td>
                    <td ><b >{getTotal(item.code).sumBalance}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/family_fees.php?token='+userToken} target='_blank'>
                <input type='hidden' name='classResult' value ={JSON.stringify(classResult, null, 2)} />

                <input type='hidden' name='familyName' value ={item.familyName} />
                <input type='hidden' name='familyCode' value ={item.code} />
                <input type='hidden' name='jwtToken' value ={Token} />

                <input type='hidden' name='sumTotal' value ={getTotal(item.code).sumTotal} />
                <input type='hidden' name='sumPaid' value ={getTotal(item.code).sumPaid} />
                <input type='hidden' name='sumBalance' value ={getTotal(item.code).sumBalance} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>)}
        </div>:''}



  
<div className="card z-depth-0">
	{payment.length!==0?	<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}   
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Fees Summary</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">
<table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>Family Name</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                </tr>
            </thead>
            <tbody >
					{payment.length!==0?payment.map((dat, id)=>{
					return	<tr key={id}>
					<td>{dat.familyName}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>
					}):[]}
				</tbody>
                <tfoot>
                <tr>
                    <td className="text-right"><b>Total</b> </td>
                    <td ><b >{studentFee.sumTotal}</b></td>
                    <td ><b >{studentFee.sumPaid}</b></td>
                    <td ><b >{studentFee.sumBalance}</b></td>
                </tr>
                </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/family_fees_summary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
                <input type='hidden' name='sumTotal' value ={studentFee.sumTotal} />
                <input type='hidden' name='sumPaid' value ={studentFee.sumPaid} />
                <input type='hidden' name='sumBalance' value ={studentFee.sumBalance} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>:''}
        </div>

          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(FamilyFees) 