import React, {useState, useEffect, useCallback, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, API_IMAGES, config, familyCode, API_URL} from '../component/include'
import Select from 'react-select'

import { useSelector } from 'react-redux'
import UsePagination from "../component/pageNumber";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getAge, getClassName } from '../component/globalFunction'
import { encrypt, userToken } from '../component/authentication'
import Cookies from 'js-cookie'

const ViewStudents=()=> {
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    

    const countries  = useSelector(state => state.rootReducer.countries);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
   
   
   
    const [activeStep, setActiveStep] = useState(1);
    const [club, setClub] = useState([]);
    const [position, setPosition] = useState([]);
    const [student, setStudent] = useState([]);
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const [occupation, setOccupation]=useState([])
    const [documents, setDocuments]=useState([])
    const [termination, setTermination]=useState([])
    const [caste, setCaste] = useState([]);
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]) 
    const [lga, setLga] = useState([]) 
    const [editStudent, setEditStudent] = useState(false); 
    const [isSubmittingQualification, setIsSubmittingQualification] = useState(false); 
    const [isSubmittingSiblings, setIsSubmittingSiblings] = useState(false); 
    const [isSubmittingDocument, setIsSubmittingDocument] = useState(false); 
    const [documentFile, setDocumentFile] = useState('')
    const [image, setImage] = useState({
        passport:'',
        cover:'',
        documentFile:''
    })
    const [allStudents, setAllStudents] = useState([]);
    const [studentId, setStudentId] = useState('');
    const [general, setGeneral] = useState({
        
        institution:'',
        program:'',
        awardYear:'',
        grade:'',
        remarks:'',

        siblingRemarks:'',
        siblingClass:'',
        school:'',
        siblingName:'',
        siblingDOB:'',


        perPage:16,
        status:[],
        gender:[],
        studentClass:[],
        
        admissionNumber:'',
        regSession:'',
        dateRegister:'',
        cardView:{ value: 'list', label: 'List View'},

        documentType:[],
        documentTitle:'',
        documentFile:'',
        passport:'',
        cover:'',
        termination_reasonLabel:'',
        termination_sessionLabel:'',

        
        pickDropRemarks:'', 
        pickDropPhone:'',  
        pickDropName:''
        

    })
    let [page, setPage] = useState(1);
    const PER_PAGE = Number(general.perPage);
  
    const count = Math.ceil(allStudents.length / PER_PAGE);
    const dataList = UsePagination(allStudents, PER_PAGE);
  
    const handleChangePage = (e, num) => {
      setPage(num);
      dataList.jump(num);
    };

    var pages = [...Array(dataList.maxPage).keys()].map(i =>  i+1);

    const steps = [{title: 'Student Detail'}, {title: 'Contact Details '}, {title: 'Parent Details '}, {title: 'Qualification'}, {title: 'Sibling Information '}, {title: 'Document'}]



    const handleFetchAllStudents =()=>{
        setNotice({...notice, 
            isLoading: true})
        var sql ="Select id, code, stateId, country, registerNumber, gender, dateOfBirth, admissionStatus, regSession, passport, studentName, admissionNumber, classID, sectionID, fatherName, dateRegister  from tbl_students where family_name ='"+familyCode+"' order by sectionID ASC"; 


    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>setAllStudents(result.data.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }


    const fetchOccupation =()=>{
        var sql ="SELECT occupation, document, house, club, position, terminationReason, program from  tbl_student_master where ID="+1;
      const fd = new FormData();
      fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
      axios.post(url, fd, config).then(result=>{

      if(result.data.length>0){
        setClub(JSON.parse(result.data.data[0].club))
        setPosition(JSON.parse(result.data.data[0].position))
      setOccupation(JSON.parse(result.data.data[0].occupation))
      setCaste(JSON.parse(result.data.data[0].house))
      setDocuments(JSON.parse(result.data.data[0].document))
      setTermination(JSON.parse(result.data.data[0].terminationReason))
      }
      })
      .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
      })
      }
    

   
    

      const handleFetchLga =(code)=>{
        const fd = new FormData(); 
        var sql ="Select id, name from tbl_cities where state_id ='"+code+"'"; 
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result => setLga(result.data.data))   
     }

     const handleFetchState =(code)=>{

      const fd = new FormData(); 
      var sql ="Select name,  id from tbl_states where country_code ='"+code+"'"; 
      fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
      axios.post(url, fd, config).then(result => setStates(result.data.data.sort((a, b) =>(a.name > b.name) ? 1 : -1)))
   }

      const  handleFetchStudent =(code)=>{
    
        const fd = new FormData();
        var sql ="Select * from tbl_students where code ='"+code+"'"; 
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        setNotice({...notice,  isLoading: true });
        axios.post(url, fd, config)
        .then(result => {
            setStudent(result.data.data)
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false
            }); 
        })   
            }
    
    const fetchNameByField =(ID, table, field)=>{
        const result = table.filter(list=>list.id===ID);
      const answer = result.map(ans=>ans[field])
        return String(answer)
      }
      
      const fetchField =(code, table, field)=>{
        const result = table.filter(list=>list.code===code);
      const answer = result.map(ans=>ans[field])
        return String(answer)
      }

      const fetchCountryName =(code)=>{
        const result = countries&&countries.filter(list=>list.iso2===code);
      const answer = result.length!==0?result[0].name:''
        return answer
      }
     

     

     const handleChangeGeneral = event =>{
		const {name, value} = event.target
        setGeneral({...general, [name] : value });
        setErrors({...errors, [name]:''})
     }

    
     const handleSubmitPickDrop = event =>{
      event.preventDefault();
    let error ={}
    if(!general.pickDropName){
      error.pickDropName ='This field is required';
  }

  if(!general.pickDropPhone){
    error.pickDropPhone ='This field is required';
}
setErrors(error)

      if(Object.keys(error).length === 0){ 


        const allPickDrop = JSON.parse(student[0].pickDrop)
        const newPickDrop = [...allPickDrop, {
          code:'p'+Math.random().toString(36).substr(2,9), 
          pickDropName:general.pickDropName, 
          pickDropPhone:general.pickDropPhone, 
          pickDropRemarks:general.pickDropRemarks }]

        const newData = student
        newData[0].pickDrop = JSON.stringify(newPickDrop)
        setStudent(newData)

        setGeneral({...general,
          pickDropName:'',
        pickDropPhone:'',
        pickDropRemarks:''
        
        })

      }
      }

     const handleSubmitQualification = event =>{
		event.preventDefault();
		setErrors(ValidateQualification(general));
		setIsSubmittingQualification(true);
    }
    
 const ValidateQualification=(values)=>{
    let errors = {};     
    if(!values.institution){
        errors.institution ='Institution /Board field is required';
    }
    if(!values.awardYear){
        errors.awardYear ='Year of Award field is required';
    }
    if(!values.program){
        errors.program ='Program field is required';
    }
    return errors;
    }

    const submitQualification=()=>{

        const allQualificaion = JSON.parse(student[0].qualifications)
        const newQualification = [...allQualificaion, {code:'q'+Math.random().toString(36).substr(2,9), institution:general.institution, program:general.program, grade:general.grade, awardYear:general.awardYear, remarks:general.remarks }]

        const newData = student
        newData[0].qualifications = JSON.stringify(newQualification)
        setStudent(newData)
        setGeneral({...general,
        institution:'',
        program:'',
        grade:'',
        remarks:'',
        awardYear:''
        
        })
        setIsSubmittingQualification(false);
    }

    const handleSubmitSiblings = event =>{
		event.preventDefault();
		setErrors(ValidateSiblings(general));
		setIsSubmittingSiblings(true);
    }
    
    const ValidateSiblings=(values)=>{
        let errors = {};     
        if(!values.siblingName){
            errors.siblingName ='Sibling Name field is required';
        }
        return errors;
        }

    const submitSiblings=()=>{ 
       
        const allSiblings = JSON.parse(student[0].siblings)
        const newSiblings = [...allSiblings, {code:'s'+Math.random().toString(36).substr(2,9), siblingName:general.siblingName, siblingClass:general.siblingClass, siblingDOB:general.siblingDOB, siblingRemarks:general.siblingRemarks, school:general.school }]

        const newData = student
        newData[0].siblings = JSON.stringify(newSiblings)
        setStudent(newData)

        setGeneral({...general,
        siblingName:'',
        siblingClass:'',
        siblingDOB:'',
        siblingRemarks:'',
        school:''
        
        })
        setIsSubmittingSiblings(false);
        
    }

    const handleSubmitDocument = event =>{
		event.preventDefault();
		setErrors(ValidateDocument(general));
		setIsSubmittingDocument(true);
    }

    const ValidateDocument=(values)=>{
        let errors = {};     
        if(!values.documentTitle){
            errors.documentTitle ='Document title field is required';
        }
        if(values.documentType.length===0){
            errors.documentType ='Please select document type';
        }
        
        if(documentFile.length===0){
            errors.documentFile ='Please upload document';
        }

        return errors;
        }

    const submitDocument=()=>{

        setNotice({...notice, 
            isLoading: true}) 

            const fd = new FormData();
            fd.append("jwt", Token);
        
      var fileUrl = 'f'+Math.random().toString(36).substr(2,9)+'.png'

        const allDocument = JSON.parse(student[0].documents)
        const newDocument = [...allDocument, {code:'d'+Math.random().toString(36).substr(2,9), documentTitle:general.documentTitle, documentType:general.documentType.value, fileUrl:fileUrl }]

        const newData = student
        newData[0].documents = JSON.stringify(newDocument)
        
        fd.append('studentCode', studentId);
        fd.append('qualifications', newData[0].qualifications);
        fd.append('siblings', newData[0].siblings);
        fd.append("image", documentFile);
        fd.append('documents', JSON.stringify(newDocument));
        fd.append('fileUrl', fileUrl);
        setStudent(newData)

        let url = ServerUrl+'/update_controller/tbl_student_document';
        axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
        }  
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 

        setGeneral({...general,
            documentTitle:'',
        documentType:[]
        })
        setDocumentFile('')
        document.getElementById('documentFile').value = ''
        setIsSubmittingDocument(false);
            
    })
}





    
useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmittingQualification){
        submitQualification()
        }
        },[errors])

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmittingSiblings){
        submitSiblings()
    }
    },[errors])

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmittingDocument){
        submitDocument()
    }
    },[errors])

    const handleSelectState = (option, action)=>{
          const std =JSON.parse(JSON.stringify(student))  
            std[0].stateId = option.value;
          setStudent(std);
          handleFetchLga(option.value);
      };
  
      const handleSelectLga = (option, action)=>{
          setGeneral({...general,  lgaLabel: option.label });
          const std =JSON.parse(JSON.stringify(student))   
              const value =option.value;
            std[0].lga = value;
          setStudent(std);
      };
  
      const handleChange = (e)=>{
          const std =JSON.parse(JSON.stringify(student))   
              const value =e.target.value;
             std[0][e.target.name] = value;
          setStudent(std);
      }

      const handleChangeMobile = (e)=>{
        const std =JSON.parse(JSON.stringify(student))   
            const value =e.target.value;
           
           std[0][e.target.name] = value.slice(0,11);
        setStudent(std);
    }
      

     


    const handleSelect = (option, action)=>{
        const std =JSON.parse(JSON.stringify(student))   
          std[0][action.name] = option.value;

        setStudent(std);

        if(action.name==='country'){
          handleFetchState(option.value)
        }
    }


    const handleSelectDocument = (option, action)=>{
        setGeneral({...general, [action.name]: option });
       setErrors({...errors, [action.name]:''})
    };


    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
           const fd = new FormData();
             fd.append("jwt", Token);
           fd.append('ID', 'code')
            fd.append('data', code)  
            let url = ServerUrl+'/delete_controller/tbl_students/'+Token;
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
                    setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                   }   
           })
           .catch((error)=>{
            setErrors({...errors, errorMessage: JSON.stringify(error.message)})
           }).finally(()=>{
            handleFetchAllStudents()
               setNotice({...notice,  isLoading: false })
           }) 
    }


    const handleRemove = (code, table)=>{  

        if(table==='qualifications'){
            const records = JSON.parse(student[0].qualifications)
           var allData = records.filter(item=>item.code !== code)
           
            const newData = JSON.parse(JSON.stringify(student)) 
               newData[0].qualifications = JSON.stringify(allData, null, 2)
               setStudent(newData)

       }  else if(table==='siblings'){

          const records = JSON.parse(student[0].siblings)
           var allData = records.filter(item=>item.code!==code)
           const newData = JSON.parse(JSON.stringify(student)) 
               newData[0].siblings = JSON.stringify(allData, null, 2)
               setStudent(newData)

       }else if(table==='pickDrop'){

        const records = JSON.parse(student[0].pickDrop)
         var allData = records.filter(item=>item.code!==code)
         const newData = JSON.parse(JSON.stringify(student)) 
             newData[0].pickDrop = JSON.stringify(allData, null, 2)
             setStudent(newData)

     }else{
        const records = JSON.parse(student[0].documents)
        var allData = records.filter(item=>item.code !== code)
        
         const newData = JSON.parse(JSON.stringify(student)) 
            newData[0].documents = JSON.stringify(allData, null, 2)
            setStudent(newData)
       }
    }
   

    const tableDocumentHeader = [
        {dataField: 'code', text: 'x',  formatter: (cell)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(cell, 'documents')}><i className="fa fa-trash"></i></button>, editable: false},
        {dataField: 'documentTitle', text: 'Title', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Document Title field is required'
              };
            }
            return true;
          }},
          {dataField: 'documentType', text: 'Type', formatter: (cell)=>fetchField(cell, documents, 'documentName'),  editable: false },
        {dataField: 'fileUrl', text: 'View', formatter: (cell)=><a href={API_IMAGES+'/students/documents/'+cell} target="_new" className="btn btn-success btn-sm"     title="View">View  <i className="fa fa-download"></i></a>,  editable: false }
              
     ];

     const handleEditStudent =(row)=>{
        setStudentId(row.code);
        handleFetchStudent(row.code)
        setEditStudent(true);
        handleFetchState(row.country)
        handleFetchLga(row.stateId)  
        setActiveStep(1); 
     structure.current.scrollIntoView({ behavior: "smooth" });
        }





    const tableHeader = [
        {dataField: 'passport', text: 'Picture', editable: false, formatter:(cell, row)=><img style={{ height:'70px' }} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+"/"+row.gender+".png"}} src={API_IMAGES+'/students/'+cell} title={row.studentName}  alt={row.studentName} />},
        {dataField: 'studentName', text: 'Student Name', sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Student Name field is required'
              };
            }
            return true;
          }, formatter:(cell, row)=><div>
              {cell}<br/> {row.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{row.admissionStatus}</span>:row.admissionStatus==='Graduated'? <span className="pcoded-badge label bg-dark">{row.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{row.admissionStatus}</span>}
          </div>},
        {dataField: 'admissionNumber', text: 'Admission Number', sort: true, editable:false}, 
        {dataField: 'fatherName',  text: 'Father Name',  sort: true},
        {dataField: 'sectionID',  text: 'Current Class', sort: true, editable:false, formatter: (cell, row) => getClassName(row.classID, row.sectionID, schoolClass)}, 
        {dataField: 'dateOfBirth',  text: 'Age',  sort: true, editor: {
            type: Type.DATE
          }, formatter:(cell)=>getAge(cell)},
        {dataField: 'gender',  text: 'Sex',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Male', label:'Male'},
                        {value:'Female', label:'Female'}
            ] }},
        {dataField: 'dateRegister', text: 'Registered date',  sort: true, editor: {
            type: Type.DATE
          } },
          {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>


<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

<div className="btn-group dropdown-split-success ">
        <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-hand"></i>Action
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          

            <a className="dropdown-item waves-effect waves-light"  href={"/student/profile/"+row.code} >  <i className='fa fa-graduation-cap'></i> Preview Student</a>

            <a disabled className="dropdown-item waves-effect waves-light" onClick={()=>handleEditStudent(row)} href="#!"><i className="icofont icofont-edit-alt"></i> Edit Student</a>
            <a className="dropdown-item waves-effect waves-light"  href="#!" onClick={()=>loginAsStudent(row)} >  <i className='fa fa-user'></i> Login as this Student</a>
            {/* <div className="dropdown-divider"></div>
          <a href="#!" data-toggle="modal" className="dropdown-item delete"  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i>Delete Student</a> */}
        </div>
    </div>

          </div>, editable: false},
     ];
    
     const StudentTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
         fd.append("jwt", Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             
           
            let url = ServerUrl+'/updateById_controller/tbl_students';
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
      
      <div className="dt-buttons btn-group pull-right"> 

    <div className="btn-group dropdown-split-primary ">




        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          
        <form method='POST' action={ServerUrl+'/excel_controller.php?api=students'} target='_blank'>
                <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='studentClass' value ={general.studentClass.value} />

                <input type='hidden' name='admissionNumber' value ={general.admissionNumber} />
                <input type='hidden' name='regSession' value ={general.regSession.value} />

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/student_records.php?token='+userToken} target='_blank'>
                <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='studentClass' value ={general.studentClass.value} />

                <input type='hidden' name='admissionNumber' value ={general.admissionNumber} />
                <input type='hidden' name='regSession' value ={general.regSession.value} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 
    </div> 

                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return props.fetchdata;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
      const tablePickDropHeader = [
        {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'pickDrop')}><i className="fa fa-trash"></i></button>, editable: false},
        {dataField: 'pickDropName', text: 'Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Name field is required'
              };
            }
            return true;
          }},
          {dataField: 'pickDropPhone', text: 'Telephone', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Telephone field is required'
              };
            }
            return true;
          }},
        {dataField: 'pickDropRemarks', text: 'Remarks',  sort: true }
              
     ];

    const tableSiblingsHeader = [
        {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'siblings')}><i className="fa fa-trash"></i></button>, editable: false},
        {dataField: 'siblingName', text: 'Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Sibling Name field is required'
              };
            }
            return true;
          }},
        {dataField: 'siblingDOB', text: 'Date of Birth', editor: {
            type: Type.DATE
          } },
        {dataField: 'school', text: 'School'},
       
        {dataField: 'siblingClass', text: 'class',  sort: true},
        {dataField: 'siblingRemarks', text: 'Remarks',  sort: true }
              
     ];
   

    const tableQualificationHeader = [
        {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'qualifications')}><i className="fa fa-trash"></i></button>, editable: false},
        {dataField: 'institution', text: 'Institution', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Institution field is required'
              };
            }
            return true;
          }},
        {dataField: 'program', text: 'Program',validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Program field is required'
              };
            }
            return true;
          }},
        {dataField: 'grade', text: 'Grade'},
       
        {dataField: 'awardYear', text: 'Year of Award',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Award Year field is required'
              };
            }
            return true;
          } },
        {dataField: 'remarks', text: 'Remarks',  sort: true }
              
     ];

  
     const BasicTable=(props)=>{
        const handleUpdate=(column, newValue, code)=>{   
            
            var table = props.table
    
             if(table==='qualifications'){
                 const records = JSON.parse(student[0].qualifications)
                var allData = records.filter(item=>item.code!==code)
                let edit = records.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                    edit.concat(allData)

                 const newData = student
                    newData[0].qualifications = JSON.stringify(records)
                    setStudent(newData)

            }  else if(table==='siblings'){

               const records = JSON.parse(student[0].siblings)
                var allData = records.filter(item=>item.code!==code)
                let edit = records.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                    edit.concat(allData)

                 const newData = student
                    newData[0].siblings = JSON.stringify(records)
                    setStudent(newData)
    
            } else if(table==='pickDrop'){

              const records = JSON.parse(student[0].pickDrop)
               var allData = records.filter(item=>item.code!==code)
               let edit = records.filter(item=>item.code===code)
                   edit[0][column.dataField] = newValue
                   edit.concat(allData)

                const newData = student
                   newData[0].pickDrop = JSON.stringify(records)
                   setStudent(newData)
                   
           } else{
                const records = JSON.parse(student[0].documents)
                var allData = records.filter(item=>item.code!==code)
                let edit = records.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                    edit.concat(allData)

                 const newData = student
                    newData[0].documents = JSON.stringify(records)
                    setStudent(newData)
            }
          }
    
    
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search  className="table-responsive"
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
    

    
 
    


    const handleOnClickStepper = (step) => {
        setActiveStep(step);   
        window.scrollTo(0,0);
    }


    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo(0,0);
    }, [activeStep])
    

    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        window.scrollTo(0,0);
       
    }, [activeStep])
    
   

    const  handleFile = function(fieldName){
        return function(newValue){                 
            const value = newValue.target.files[0]
          // setImage(value)
       readURL(newValue);
       }
       
      } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            let newImage = "viewPassport";
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" ||  extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 0.6) {
            displayMessage = PreviewImage +': File uploaded is more than maximum size Allow. it exceeds 500 Kb';
            document.getElementById(PreviewImage).value = '';
           setImage({...image, [PreviewImage]:''})
           setGeneral({...general, [PreviewImage]:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e) {
                  
                    
                    if(PreviewImage!=='documentFile'){
                        setGeneral({...general, [PreviewImage]:input.target.files[0]})
                        setImage({...image, [PreviewImage]:e.target.result})

                    }else{
                        setDocumentFile(input.target.files[0])
                    }
                    
                }
                reader.readAsDataURL(input.target.files[0]);
                
            }
        }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500Kb'
       document.getElementById(PreviewImage).value = '';

       if(PreviewImage!=='documentFile'){
        setImage({...image, [PreviewImage]:''})
           setGeneral({...general, [PreviewImage]:''})
    }else{
        setDocumentFile('')
    }
       
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
        
    } 



    const  handleUpdateStudent=()=>{  
        
        let formData = student[0]
        const fd = new FormData(); 
        fd.append("jwt", Token);
        for(let k in formData){
            fd.append(k,formData[k]);
        }
    
    
        if(general.passport.length!==0){
        fd.append('photo', general.passport);
       }
       if(general.cover.length!==0){
           
        fd.append('banner', general.cover);
    } 
    

  setNotice({...notice,   isLoading: true}) 
    
           let url = ServerUrl+'/update_controller/tbl_students_update'
    
           axios.post(url, fd, config)
          .then(response =>{
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else {
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
        }  
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
          setEditStudent(false) 
           setImage({
              passport:'',
              cover:'',
          })
          setGeneral({...general, passport:'', cover:''}) 
          handleFetchAllStudents()
    })  
    
    }


    const loginAsStudent =(row)=>{
      //1000 * 60 * SESSION_IDEL_MINUTES

        var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
       var inOneMinutes = Date.now() + 58 * 60 * 1000;
       var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
       let domain = '.aduvieportal.org'
Cookies.set("_stbexpt", inOneMinutes, { expires: inOneHours, domain: domain });
  Cookies.set("_stexpt", inSixtyMinutes, { expires: inOneHours, domain: domain });


  //Cookies.set('name', 'value', { domain: studentUrl })
    Cookies.set("stkauscd", row.code, { expires: inOneHours, domain: domain });
    Cookies.set("stkamtk", Token, { expires: inOneHours, domain: domain  });
    Cookies.set("stkstnm", encrypt(row.studentName), { expires: inOneHours, domain: domain  });
    Cookies.set("fmcdk", row.family_name, { expires: inOneHours, domain: domain  });
    Cookies.set("sclkysd", row.classID, { expires: inOneHours, domain: domain  });
    Cookies.set("ssckayd", row.sectionID, { expires: inOneHours, domain: domain  });


    //window.open(StudentUrl+'/dashboard', '_blank') 

     }
     


     function updateCover(){  

        if(general.cover.length===0){
          Alerts('Error!', 'danger', 'Kindly upload  cover image')
        }else{
                  setNotice({...notice,   isLoading: true})     
                
                    
                    const fd = new FormData();
                    fd.append('jwt', Token);
                    fd.append('studentName', student[0].studentName);
                    fd.append('studentCode', student[0].code);
                    fd.append('coverUrl', general.cover);

                     let url = ServerUrl+'/save_controller/tbl_cover_image'
                    axios.post(url, fd, config)
                    .then(response =>{
                     if(response.data.type ==='success'){
                      Alerts('Saved!', 'success', response.data.message)
                        } else{
                      Alerts('Error!', 'danger', response.data)
                        }   
                    })
                    .catch((error)=>{
                      Alerts('Error!', 'danger', error.message)
                    }).finally(()=>{
                      setNotice({...notice, 
                        isLoading: false
                      })
                      setGeneral({...general, cover:''})
                  //setImage({...image, cover:''})
                      //window.location.reload()
                    }) 
                }
              }

   function updatePassport(){  

    if(general.passport.length===0){
      Alerts('Error!', 'danger', 'Kindly upload  passport')
    }else{
           
                const fd = new FormData();
                fd.append('jwt', Token);
                
                fd.append('studentName', student[0].studentName);
                fd.append('studentCode', student[0].code);
                fd.append('imageUrl', general.passport);
                	
                 let url = ServerUrl+'/save_controller/tbl_passport'
                axios.post(url, fd, config)
                .then(response =>{
                  

                 if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                    } else{
                  Alerts('Error!', 'danger', JSON.stringify(response.data.message))
                    }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice,  isLoading: false  })
                  setGeneral({...general, passport:''})
                  //setImage({...image, passport:''})
                 // window.location.reload()
                }) 
            }
          }



    
useEffect(()=>{   
    fetchOccupation();    
    handleFetchAllStudents()
},[]);
        return (<> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Students">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Students</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                               {/*  <!-- Edit student start here  --> */}
                               <div ref={structure} />
                               {editStudent ?   <div className="row">
                                <div className="col-sm-12">
                               {/*  <!-- Promotion container start here  --> */}
                                                
<div className="card z-depth-0">

    <div className="card-header">
        <h5><a href="#!" > <i className="fa fa-print" ></i> </a> Modify Details of {student&&student.map((st)=>st.studentName)}</h5>
        <div className="card-header-right">
            <ul className="list-unstyled card-option">
                <li><i className="feather icon-maximizes full-card"></i></li>
                <li><i className="feather icon-minus minimize-card"></i></li>
                <li><i className="feather icon-trash-2 close-card"></i></li>
            </ul>
        </div>
    </div>
        <div className="card-block">
                                        
    <div className="container">
        
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>

        
       {student&&student.map((st, idx)=> <div key={idx} >

{activeStep === 1 ? <div className="row setup-content" id="student" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">
                <section className="col-md-4">
                                <div className="form-group">
                        <label >Name</label>
                     <input type="text" className={errors.studentName  ? 'form-control form-control-danger' : 'form-control'} name='studentName' onChange={handleChange} value={st.studentName} /><span style={{color:'red'}}>{errors.studentName && errors.studentName}</span>
                                 </div>
                               <div className="form-group">
                    <label > Class</label> 
                    <input type="text" className="form-control" readOnly value ={getClassName(st.classID, st.sectionID, schoolClass)} />
                                                
                                            </div>
                                            <div className="form-group">
                        <label >Tribe</label>
                        <input type="text" className="form-control" name="tribe" onChange={handleChange} value={st.tribe} />
                                                
                                            </div>
                                            <div className="form-group">
                        <label >Language Spoken</label>
                        <input type="text" className="form-control" name="languageSpoken" onChange={handleChange} value={st.languageSpoken} />
                                                
                                            </div>

                                          
                                           
                                        </section>	

                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Date of Registration</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control" disabled={true} name="dateRegister" onChange={handleChange} value={st.dateRegister} />
                </div> </div>

                <div className="form-group">
                        <label >Date of Birth</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control " name="dateOfBirth"  onChange={handleChange} value={st.dateOfBirth} />
                </div>
                                                
                     </div> 

                       
                                           
                                            <div className="form-group">
                        <label >Admission Number</label>
                        <input type="text" className="form-control" name="admissionNumber" disabled={true} onChange={handleChange} value={st.admissionNumber} />
                                                
                                            </div>
                                     
                                            <div className="form-group">
							
                   
                    <input id="passport"  onChange={handleFile('passport')} className="form-control file" name="passport" type="file" accept="image/*" /> 
									</div>



                                    <div className="form-group"> 
        <a href="#!" htmlFor="passport" onClick={()=>document.getElementById('passport').click()} className="profile-image" title='change profile'>

                            {st.passport!==''?image.passport===''?
            
                <img id="viewPassport2" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/'+st.gender+'.png'}} src={`${API_IMAGES}/students/${st.passport}`}  style={{display:'block', height:'150px',width:'150px'}} title={st.studentName} alt={st.studentName} /> :

                <img id="viewPassport" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/'+st.gender+'.png'}} src={image.passport}  style={{display:'block',height:'150px',width:'150px'}} title={st.studentName} alt={st.studentName} /> :  

                 <img id="viewPassport" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/'+st.gender+'.png'}} src={image.passport}  style={{display:'block',height:'150px',width:'150px'}} title={st.studentName} alt={st.studentName} />        }

  </a>

                    
{general.passport===''? <label  htmlFor="passport" title="Change Passport"   className='btn btn-sm'><i className="fa fa-camera fa-3x"></i> Change Passport</label>:<button type='button' onClick={updatePassport} className='btn btn-success btn-sm'><i className="fa fa-image"></i> Save</button>}



                                            </div>


                    </section>

                                        <section className="col-md-4">
                                                                            
                                        
                                            <div className="form-group">
                        <label >Blood Group</label>
                        <Select  options={[
                { value: 'A Plus', label: 'A+' },
                { value: 'A Minus', label: 'A-'},
                { value: 'AB Plus', label: 'AB+'},
                { value: 'AB Minus', label: 'AB-' },
                { value: 'B Plus', label: 'B+'  },
                { value: 'B Minus', label: 'B-' },
                { value: 'O Plus', label: 'O+' },
                { value: 'O Minus', label: 'O-' }
                ]} 
onChange={handleSelect}  name="bloodGroup" value={{value: st.bloodGroup, label: st.bloodGroup}} styles={selectStyles} /> 
                                                
                                            </div>
                                          
                                            <div className="form-group">
                        <label > Gender</label>
                        <Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                ]} 
                        onChange={handleSelect}  name="gender" value={{value: st.gender, label: st.gender}} styles={selectStyles}  /> 
             </div>  


                         <div className="form-group">
                        <label >Register Number</label>
                        <input type="text" className="form-control" disabled={true} name="registerNumber" onChange={handleChange} value={st.registerNumber} />
                                                
                                            </div>


                                            <div className="form-group">
                        <button type='button' onClick={()=>loginAsStudent(st)} className="btn btn-inverse"  > <i className="fa fa-graduation-cap"></i>Login as this student </button>
                   
                    <input id="cover"  onChange={handleFile('cover')} className="form-control file" name="cover" type="file" accept="image/*" /> 
					
										</div>
                                           

                                            <div className="form-group"> 
                        <a href="#!" htmlFor="cover" onClick={()=>document.getElementById('cover').click()} className="profile-image" title='change profile'>
                            {st.cover!==''?image.cover===''?
            
                <img id="viewBanner" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/background/main.jpg'}} src={`${API_IMAGES}/students/${st.cover}`}  style={{display:'block', height:'100px',width:'200px'}} title={st.studentName} alt={st.studentName} /> :

                <img id="viewBanner2" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/background/main.jpg'}} src={image.cover}  style={{display:'block',height:'100px',width:'200px'}} title={st.studentName} alt={st.studentName} /> :  

                 <img id="viewBanner3" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/background/main.jpg'}} src={image.cover}  style={{display:'block',height:'100px',width:'200px'}} title={st.studentName} alt={st.studentName} />        }
</a>

&nbsp;

  {general.cover===''? 
  <label  htmlFor="cover" title="Change Cover" className='btn  btn-sm'><i className="fa fa-camera fa-2x"></i> Change Cover </label>
:<button type='button' onClick={updateCover} className='btn btn-success btn-sm'><i className="fa fa-image"></i> Save Background</button>}
                                            </div>

                                        </section>
                                        </div>
                                                
            </div></div>
            
                </div>
                
    </div> 
   
    
   : activeStep === 2 ?  <div className="row setup-content" id="contact" >
                <div className="col-md-12">

                <div className="card z-depth-0">
                <div className="card-block">
                <div className="row">
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Mobile</label>
                        <input type="text" className="form-control" maxLength={11} name="mobile" max={13} onChange={handleChangeMobile} value={st.mobile} />
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Alternate Mobile</label>
                        <textarea  type="text" className="form-control " value={st.altMobile} name="altMobile"  onChange={handleChangeMobile}  >{st.altMobile}</textarea>

                                                
                                            </div>
                                            <div className="form-group">
                        <label >Country</label>

                        <Select   options={countries.map((data, idx)=> {
                                    return {key:idx, value: data.iso2, label: data.name };
                                  })}
onChange={handleSelect}  name="country" value={{value: st.country, label: fetchCountryName(st.country)}} styles={selectStyles} /> 

                                                
                                            </div>
                                            
                     </section>	
                    <section className="col-md-4">
                                        <div className="form-group">
                        <label >Student Email</label>
                        <input type="email" className="form-control" name="email" onChange={handleChange} value={st.email} />
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Contact Address</label>
                        
                        <textarea  type="text" className="form-control " value={st.currentAddress} name="currentAddress"  onChange={handleChange}  >{st.currentAddress}</textarea>
                                                  
                                            </div>
                                            <div className="form-group">
                        <label >State</label>
                        <Select   options={states&&states.map((data, idx)=> {
                                    return {key:idx, value: data.id, label: data.name };
                                  })}
onChange={handleSelectState}  name="stateId" value={{value: st.stateId, label: fetchNameByField(st.stateId, states, 'name')}} styles={selectStyles} /> 
                                                
                                            </div>
                                            
                      </section>
                          <section className="col-md-4">
                                        
                                            <div className="form-group">
                        <label >Place of Birth</label>
                        <input type="text" className="form-control" name="placeOfBirth" onChange={handleChange} value={st.placeOfBirth} />
                                                
                                            </div>

                                            <div className="form-group">
                        <label >Permanent Address</label>
                <textarea  type="text" className="form-control " value={st.permAddress} name="permAddress"  onChange={handleChange}  >{st.permAddress}</textarea>
                                                
                                            </div>

                                            <div className="form-group">
                        <label >City</label>
                        <Select   options={lga&&lga.map((data, idx)=> {
                                    return {key:idx, value: data.id, label: data.name, index:idx };
                                  })}
onChange={handleSelectLga}  name="lga" value={{value: st.lga, label:fetchNameByField(st.lga, lga, 'name')}} styles={selectStyles} /> 
                                                
                                            </div>
                                        </section>
                                        </div>
                                        </div>
                
                </div>		  
                            
                </div>

                <div className="col-md-12">
                  
                  <div className="card z-depth-0">
                  <div className="card-block">
                  <div className="row">
                  <section className="col-md-4">
                                          <div className="form-group">
                          <label >Club</label>
                          <Select   options={club.map((dat, idx)=> {
                                    return {key:idx, value: dat.code, label: dat.clubName };
                                  })}
onChange={handleSelect}  name="club" value={{value: st.club, label: fetchField(st.club, club, 'clubName')}}  styles={selectStyles} /> 
                                                  
                                              </div>
                                              
                                              <div className="form-group">
                        <label >House</label>
                       <Select   options={caste.map((dat, idx)=> {
                                    return {key:idx, value: dat.code, label: dat.houseName };
                                  })}
onChange={handleSelect}  name="house" value={{value: st.house, label: fetchField(st.house, caste, 'houseName')}}  styles={selectStyles} /> 
                                                
                                            </div>

                                         
                                              
                       </section>	
                      <section className="col-md-4">
                                          <div className="form-group">
                          <label >Post</label>
                          <Select   options={position.map((dat, idx)=> {
                                    return {key:idx, value: dat.code, label: dat.positionName };
                                  })}
onChange={handleSelect}  name="position" value={{value: st.position, label: fetchField(st.position, position, 'positionName')}}  styles={selectStyles} /> 
                                                  
                                              </div>
                                              
                                              <div className="form-group">
                        <label >Weight</label>
                        <input maxLength="5" type="text" name="weight" onChange={handleChange} value={st.weight} className="form-control" />

                                                
                                            </div>
                                        
                                           
                        </section>
                         
                        <section className="col-md-4">
                                       
                                              
                        <div className="form-group">
                        <label >Height</label>
                        <input type="text" className="form-control" name="height"  onChange={handleChange} value={st.height} />
                                                
                                            </div>


                                            <div className="form-group">
                        <label >Religion</label>
                        <Select  options={[
                    { value: 'Christianity', label: 'Christianity' },
                    { value: 'Islam', label: 'Islam'},
                ]} 
onChange={handleSelect}  name="religion" value={{value: st.religion, label: st.religion}} styles={selectStyles} />
                                                
                                            </div>
                                              
                       </section>	
                                          </div>
                                          </div>
                  
                  </div>		  
                              
                  </div>
                
    </div> : activeStep === 3 ?   <div className="row setup-content" id="parent" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
            <div className="card-block">
                <div className="card z-depth-0">
        
            <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">

                <div className="form-group">
                        <label >Father Name</label>
                        <input type="text" className="form-control" name="fatherName" onChange={handleChange} value={st.fatherName} />
                                                
                                            </div>
                                            

                                        <div className="form-group">
                        <label >Father Date Of Birth</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control " name="fatherDOB"  onChange={handleChange} value={st.fatherDOB} />
                </div>
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Father Qualification</label>
                        <Select   options={documents.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.documentName};
                                  })}
onChange={handleSelect}  name="fatherQualification" value={{value: st.fatherQualification, label: fetchField(st.fatherQualification, documents, 'documentName')}} styles={selectStyles} /> 
                                                
                                            </div>
                                           
                                            
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Father Mobile</label>
                        <input type="text" className="form-control mobile" maxLength={11} name="father_mobile"  onChange={handleChange} value={st.father_mobile} />
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Father Occupation</label>
                        <Select   options={occupation.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.occupationName};
                                  })}
onChange={handleSelect}  name="fatherOccupation" value={{value: st.fatherOccupation, label: fetchField(st.fatherOccupation, occupation, 'occupationName')}} styles={selectStyles} /> 
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Father Designation</label>
                        <input type="text" className="form-control " name="fatherDesignation"  onChange={handleChange} value={st.fatherDesignation} />
                                                
                                            </div>
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Father Email</label>
                        <input type="email" className="form-control " name="fatherEmail"  onChange={handleChange} value={st.fatherEmail} />
                                                
                                            </div>
                                        
                                            <div className="form-group">
                        <label >Father Organization</label>
                        <input type="text" className="form-control " name="fatherOrganization"  onChange={handleChange} value={st.fatherOrganization} />
                                                
                                            </div>
                                        </section>
                                        </div>
                                        </div>
                
                </div>	
                        {/*  <!-- Mother details here  --> */}
                        <div className="card z-depth-0">
        
            <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                <div className="form-group">
                        <label >Mother Name</label>
                        <input type="text" className="form-control" name="motherName" onChange={handleChange} value={st.motherName} />
                                                
                                            </div>

                                        <div className="form-group">
                        <label >Mother Date Of Birth</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
                <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control pickdate" placeholder="Mother Date Of Birth" name="motherDOB"  onChange={handleChange} value={st.motherDOB} />
                </div>
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Mother Qualification</label>
                        <Select   options={documents.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.documentName};
                                  })}
onChange={handleSelect}  name="motherQualification" value={{value: st.motherQualification, label: fetchField(st.motherQualification, documents, 'documentName')}} styles={selectStyles} /> 
                                                
                                            </div>
                                           
                                            
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Mother Mobile</label>
                        <input type="text" className="form-control mobile" maxLength={11} name="mother_mobile"  onChange={handleChange} value={st.mother_mobile} />
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Mother Occupation</label>
                        <Select   options={occupation.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.occupationName };
                                  })}
onChange={handleSelect}  name="motherOccupation" value={{value: st.motherOccupation, label: fetchField(st.motherOccupation, occupation, 'occupationName')}} styles={selectStyles} /> 
                                                
                                            </div>
                                            
                                            <div className="form-group">
                        <label >Mother Designation</label>
                        <input type="text" className="form-control " name="motherDesignation"  onChange={handleChange} value={st.motherDesignation} />
                                                
                                            </div>
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Mother Email</label>
                        <input type="email" className="form-control " name="motherEmail"  onChange={handleChange} value={st.motherEmail} />
                                                
                                            </div>
                                        
                                            <div className="form-group">
                        <label >Mother Organization</label>
                        <input type="text" className="form-control " name="motherOrganization"  onChange={handleChange} value={st.motherOrganization} />
                                                
                                            </div>
                                        </section>
                                        </div>
                                        </div>
                
                </div>	</div>
                
                </div>	
                                       
                </div>
                
    </div>
    
   : activeStep === 4 ?   <div className="row setup-content" id="qualification" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
                
                       {/*  <!-- Qulaification details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Name of Institution<span style={{color:'red'}}>*</span></label>
                    
                    <input type="text" className={errors.institution  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="institution" value= {general.institution} placeholder="Institution" /><span style={{color:'red'}}>{errors.institution && errors.institution}</span>
                </div>
                                                
                                            
                                            <div className="form-group">
                        <label >Grade</label>
                        <input type="text" onChange={handleChangeGeneral} name="grade" value= {general.grade} className="form-control " />
                                                
                                            </div>
                                            
                                            
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Program<span style={{color:'red'}}>*</span></label>
                        <input type="text" className={errors.program  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="program" value= {general.program} />
                        <span style={{color:'red'}}>{errors.program && errors.program}</span>             
                                            </div>
                                        <div className="form-group">
                        <label >Year of Award<span style={{color:'red'}}>*</span></label>
                        <input type="text" className={errors.awardYear  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="awardYear" value= {general.awardYear} /><span style={{color:'red'}}>{errors.awardYear && errors.awardYear}</span>
                                                
                                            </div>
                                            
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Remarks</label>
                        <input type="text" className="form-control " onChange={handleChangeGeneral} name="remarks" value= {general.remarks}  />
                                                
                                            </div>
                                        
                                            <br/>
                                                <footer className="pull-right">
                        <button type="button" onClick={handleSubmitQualification} className="btn btn-inverse" ><i className="fa fa-save" ></i> Save </button>
            </footer>         
                                        </section>
                                        </div>
                                        </div>
                
                </div>	
                
                
                <div className="card z-depth-0">
        
        <div className="card-block">
                                    
                <section className="col-md-12">
                    <div className="table-responsive">

     <BasicTable data={student&&JSON.parse(student[0].qualifications)} table='qualifications' columns={tableQualificationHeader}  />

       </div>    </section>      </div>
                
                </div>	
                
                </div>
                
                </div>	
                            
                </div>
                
    </div>
    
    
    
   : activeStep === 5 ?   <div className="row setup-content" id="sibling" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
               	
                        {/*  <!-- Siblin details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Name </label>
                    
                    <input type="text"  name="siblingName" className={errors.siblingName  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} value= {general.siblingName} placeholder="Sibling Name" /><span style={{color:'red'}}>{errors.siblingName && errors.siblingName}</span>
                </div>
                                                
                <div className="form-group">
                        <label >School</label>
                        <input type="text" name="school" className="form-control "   onChange={handleChangeGeneral} value= {general.school} placeholder="School"  />
                                                
                                            </div>   
                                           
                                            
                                            
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Class</label>
                        <input type="text" name="siblingClass" className="form-control "  onChange={handleChangeGeneral} value= {general.siblingClass} placeholder="Class" />
                                                
                                            </div>
                                        
                                            
                                            <div className="form-group">
                        <label >Birthday</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" name="siblingDOB" className="form-control pickdate" placeholder="YYYY-MM-DD" onChange={handleChangeGeneral} value= {general.siblingDOB} />
                </div>
                                                
                                            </div>     
                                            
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Remarks</label>
                        <input type="text"  name="siblingRemarks"  className="form-control " onChange={handleChangeGeneral} value= {general.siblingRemarks} placeholder="Remarks"  />
                                                
                                            </div>
                                        
                                            <br/>
                                                <footer className="pull-right">
                        <button type="button" onClick={handleSubmitSiblings} className="btn btn-inverse" ><i className="fa fa-save" ></i> Save </button>
            </footer>
                                            
                                        </section>
                                        </div>
                                        </div>
                
                </div>	
                <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-12">
                    <div className="table-responsive">
                    <BasicTable data={student&&JSON.parse(student[0].siblings)} table={'siblings'} columns={tableSiblingsHeader}  /></div>
                                        </section>
                                        </div>
                                        </div>
                </div>
                
                
                </div>
                
                </div>	
                            
                </div>



                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
               	
                        {/*  <!-- Siblin details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                  <div className="col-md-12">
                  <h3>Pick Up & Drop</h3>
                  <hr/>
                  </div>
                                    
            {/*     <section className="col-md-4">
                                        <div className="form-group">
                        <label >Name </label>
                    
                    <input type="text"  name="pickDropName" className={errors.pickDropName  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} value= {general.pickDropName} placeholder="Enter Name" /><span style={{color:'red'}}>{errors.pickDropName && errors.pickDropName}</span>
                </div>
                                           
                                            
                                            
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Telephone</label>
                        <input type="text"  name="pickDropPhone" className={errors.pickDropPhone  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} value= {general.pickDropPhone} placeholder="Enter Name" /><span style={{color:'red'}}>{errors.pickDropPhone && errors.pickDropPhone}</span>                 
                                            </div>
                                           
                                          
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Remarks</label>
                        <input type="text"  name="pickDropRemarks"  className="form-control " onChange={handleChangeGeneral} value= {general.pickDropRemarks} placeholder="Remarks"  />
                                                
                                            </div>
                                        
                                            <br/>
                                                <footer className="pull-right">
                        <button type="button" onClick={handleSubmitPickDrop} className="btn btn-inverse" ><i className="fa fa-save" ></i> Save </button>
            </footer>
                                            
                                        </section> */}
                                        </div>
                                        </div>
                
                </div>	
                <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-12">
                    <div className="table-responsive">
                    <BasicTable data={student&&JSON.parse(student[0].pickDrop)} table={'pickDrop'} columns={tablePickDropHeader}  /></div>
                                        </section>
                                        </div>
                                        </div>
                </div>
                
                
                </div>
                
                </div>	
                            
                </div>

                
                
    </div>
    
    
   :  <div className="row setup-content" id="document" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
               
                	
                        {/* <!-- document details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Title <span style={{color:'red'}}>*</span></label>
                    
                    <input type="text" className={errors.documentTitle  ? 'form-control form-control-danger' : 'form-control'} name="documentTitle" onChange={handleChangeGeneral} value= {general.documentTitle} placeholder="Document title" /><span style={{color:'red'}}>{errors.documentTitle && errors.documentTitle}</span>
                </div>     </section>	
                                      
                                      
                                      
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Document <span style={{color:'red'}}>*</span></label>
                        <Select   options={documents.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.documentName };
                                  })}
onChange={handleSelectDocument}  name="documentType" value={general.documentType} styles={selectStyles} /> 
                                                <span style={{color:'red'}}>{errors.documentType && errors.documentType }</span>
                                            </div>
                                        
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
							<label  >Choose Document <span style={{color:'red'}}>*</span></label>
                   
                    <input id="documentFile"  onChange={handleFile('documentFile')} className={errors.documentFile  ? 'form-control form-control-danger' : 'form-control'}  name="documentFile" type="file"  /> 
                    <span style={{color:'red'}}>{errors.documentFile && errors.documentFile }</span>
                   
									</div>
                                        </section>
                                       
                                        </div>
                                        
                                        <footer className="pull-right">
                        <button type="button" className="btn btn-inverse" onClick={handleSubmitDocument} ><i className="fa fa-save" ></i> Save </button>
            </footer></div>
                </div>
            
                <div className="card z-depth-0">
        
        <div className="card-block">
                                    
                <div className="col-md-12">
                    <div className="table-responsive">
                    <BasicTable data={student&&JSON.parse(student[0].documents)}  table={'documents'} columns={tableDocumentHeader}  />
                    </div>
                                        </div>
                                        </div>
                                        </div>
            </div>
                
                </div>	
                               
                </div>
                
    </div> }
        </div>
       )}
        </div>

    <button onClick={activeStep === steps.length ? handleUpdateStudent : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Save Changes</i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }  

</div>
        </div>
    </div></div>:''    
    
    }
    
    
    
                                    <div className="row  ">
                                    <div className="col-sm-12">
                               

                  <div className="card z-depth-0">
                  <div className="table-responsive">
{allStudents.length!==0?     <div className="card-block">
<div className="row">
{allStudents&&dataList.currentData().map((std, id)=>
<section key={id} className="col-md-3">
   <button type="button"   className='btn btn-default' style={{width:'100%'}}> 
  
  
   <img style={{ textAlign:'center', height:'100px', width:'100px'}} className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGES+'/'+std.gender+'.png'}} src={`${API_IMAGES}/students/${std.passport}`} alt="" />

   <div ><b>{ std.studentName.length>20?std.studentName.slice(0, 17)+'...':std.studentName}</b></div>
   <label>({std.admissionNumber})</label>
   <div>{getClassName(std.classID, std.sectionID, schoolClass)}</div>
   
<div style={{width:'50%', textAlign:'left', float:'left', fontSize:'13px'}}>Reg No <br/>Gender<br/>Status</div>
<div style={{width:'50%', textAlign:'right', float:'right', fontSize:'13px'}}>{std.registerNumber} <br/>{std.gender}<br/> {std.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{std.admissionStatus}</span>:std.admissionStatus==='Graduated'? <span className="pcoded-badge label bg-dark">{std.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{std.admissionStatus}</span>}</div>
   </button>
       <div className="input-group" >

       <button type="button" disabled onClick={()=>handleEditStudent(std)}   className="btn btn-warning form-control btn-sm" >  <i className='fa fa-edit'></i> Edit</button>

       


           <a href={"/student/profile/"+std.code}    className="form-control btn btn-inverse btn-sm" > <i className='fa fa-graduation-cap'></i> Profile</a>
       </div>
   </section>
   
)}



</div>







{allStudents && allStudents.length!==0?
<div className="row">
<div className="col-md-6">

<div className=' form-group'>
<select name='perPage' value={general.perPage}  style={{width:'60px', height:'30px'}} onChange={handleChangeGeneral}>
   
<option value='16'>16</option>
<option value='32'>32</option>
<option value='64'>64</option>
   <option value='96'>96</option>
   <option value='384'>384</option>
   <option value={allStudents.length }>All</option>
</select>&nbsp;
<span >Showing 1 to { dataList.currentData().length } of { allStudents.length } items
</span>


</div>
</div>
<div className="col-md-6 ">

   <div className="dataTables_paginate paging_simple_numbers" id="base-style_paginate">
       <ul className="pagination">

       <li className={page === 1 ? ' paginate_button page-item previous disabled' : 'paginate_button page-item previous'}  >
 <a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page - 1)} ><i className="czi-arrow-left mr-2"></i>Previous</a></li>

{pages.map((item, index) =>
<li key={index} className={page===item?' paginate_button page-item active':'paginate_button page-item'}><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, item)}>{item}</a></li>
   
)}
     
<li className={page === dataList.maxPage  ? ' paginate_button page-item next disabled' : 'paginate_button page-item next'}  ><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page + 1)} aria-label="Next">Next</a></li>

       </ul>
       </div>
       
       
       </div>


  </div>:''}

</div>:''}</div></div>




    
    </div>
    </div>
    
    
    
    </div>
               
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ViewStudents) 