import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config, classID, sectionID, studentCode} from '../component/include'
import Select from 'react-select'
import { getAge, getClassField, getResultHead, getClassName, FormatNumber, longDate, getPosition, getRank, getCAPoint, sort, getBritishDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const ViewResult =()=> {
    

useEffect(()=>{
    /* fetchHouse()
    fetchSettings()
    fetchReMark()
    fetchExamType()
    fetchAssessment()
    fetchAssementGrade()
    
    fetchMyStudentClass() */
},[]);

        return ( <Template menu="Academics" submenu='Terminal Result'>
            
          {/*   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } */}
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Terminal Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Terminal Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

   </div></div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(ViewResult) 