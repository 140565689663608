import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, Currency, userCode, config, PSKLIVE, API_URL} from '../../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { usePaystackPayment } from 'react-paystack';

const FeesPayment=()=>{
	const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
	
	const [feesTitle, setFeesTitle] =  useState('Fees Payment');
const [feetype, setFeetype] =  useState([]);
const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
	student:[],
	receiptNo:Math.random().toString(36).substring(2,9).toUpperCase(),
	paymentMethod:[],
	amountPaid:0,
	remark:'',
	paymentDate:'',
	installment:0
});
const [fees, setFees] = useState([]);
const [feeStructure, setFeeStructure] = useState({
	structure:[], 
			feeCharge:'', 
			paid:0, 
			balance:0,
			total:0, 
			code:'', 
			classID:'', 
			sectionID:'',
			tuition_fee:'',
			school_fee:0,
			discount_percent:'',
			discountAmount:'',
			remark:'',
			fees:''
});
const [student, setStudent] = useState([]);



const handlePaystackFees =()=>{
	var amount = 0
	var amountToCharge = (0.015 * Number(studentFee.installment))
if(Number(studentFee.installment) <2500){

	amount =  (Number(studentFee.installment)+ (amountToCharge)) * 100

}else if(amountToCharge>2000){
		amount = (Number(studentFee.installment)+ (2000)) * 100
	}else{
		
	amount =  (Number(studentFee.installment)+ (amountToCharge+100)) * 100
	}
	return amount;
}


var amountTopay = handlePaystackFees()
var txRef = (new Date()).getTime().toString();


const fetchStudent =()=>{
    var sql ="SELECT code, gender, admissionNumber, registerNumber, studentName, email, mobile,  motherEmail, fatherEmail from tbl_students where  code ='"+userCode+"'"
    const fd = new FormData();
fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
	if(Array.isArray(result.data.data)){

		setStudent(result.data.data[0])
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const getFees=(ID)=>{
	const rs = feetype.filter(item=>item.ID===ID)

	return rs.length!==0?rs[0].fees:''
}
const  handleFetchFeeType =()=>{
	let url = API_URL + '/fetch/data/tbl_fee_type'
axios.get(url, config)
	.then(result =>setFeetype(result.data.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', JSON.stringify(error.message)) 
	})    
		}


const fetchPayment =()=>{
	setNotice({...notice, isLoading: true}) 
	var sql ="SELECT CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, h.code, h.amount,  h.receiptNo, h.status, h.paymentMode, h.datePaid, h.remark from tbl_fees_charge f,  tbl_fees_payment_history h where h.feesCharge = f.code and  h.studentCode ='"+userCode+"'"

const fd = new FormData();
fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
	
setPayment(result.data.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchFees =()=>{
	setNotice({...notice, isLoading: true}) 
	var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.code, s.feeCharge, s.total, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code) as paid  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code and s.studentCode = '"+userCode+"' order by f.feesCharge" ;

	const fd = new FormData();
fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
setFees(result.data.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}
	
	const fetchFeestructure =(row)=>{
		setFeesTitle(row.fees)
		
		setStudentFee({...studentFee, installment:Number(row.total) - Number(row.paid)})

		setNotice({...notice, isLoading: true}) 
		var sql ="Select feeStructure, tuition_fee, school_fee, discount_percent, discountAmount, total, feeCharge,  classID, sectionID from tbl_student_fees where code = '"+row.code+"'" ;
const fd = new FormData();
	fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
	axios.post(url, fd, config).then(result=>{
		if(result.data.data.length!==0){
			let data = result.data.data[0]
			setFeeStructure({
				structure:JSON.parse(data.feeStructure), 
				feeCharge:data.feeCharge, 
				paid:row.paid, 
				balance:Number(row.total)-Number(row.paid),
				total:data.total, 
				code:row.code, 
				classID:data.classID, 
				sectionID:data.sectionID,
				tuition_fee:data.tuition_fee,
				school_fee:data.school_fee,
				discount_percent:data.discount_percent,
				discountAmount:data.discountAmount,
				remark:data.remark,
				fees:row.fees
			
			})
	
			structure.current.scrollIntoView({ behavior: "smooth" });
			}
	})
	.catch((error)=>{
	Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, isLoading: false})
		
	})
	}

		 function submitPayment(ref){  
    
			var totalPaid = Number(feeStructure.paid) + Number(feeStructure.balance)
			var status = totalPaid>=Number(feeStructure.total)?'PAID':'PARTIAL PAYMENT'
			
			const fd = new FormData();
			fd.append('status', status);
			fd.append('feesCharge', feeStructure.feeCharge);
			fd.append('scheduleCode', feeStructure.code);
			fd.append('studentCode', userCode);
			fd.append('paymentMode', 'Online - Paystack');
			fd.append('receiptNo', ref.transaction);
			fd.append('classID', feeStructure.classID);
			fd.append('sectionID', feeStructure.sectionID);
			fd.append('amountPaid', Number(feeStructure.balance));
			fd.append('paidBy', 'studentCode: '+userCode);
			fd.append('paymentDate', new Date().toISOString().slice(0,10));
			fd.append('platform', 'Paystack');
			fd.append('tx_ref', txRef);
			fd.append('remark', feesTitle);
			fd.append('customer', 'studentCode: '+userCode);

			setNotice({...notice,  isLoading: true}) 
		
				 let url = API_URL+'/fees/payment/add'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.status ===200) {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', response.data.message)
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
				.finally(()=>{
					fetchFees()
					fetchPayment()
				  setNotice({...notice, isLoading: false})  
				 setStudentFee({...studentFee,
					receiptNo:Math.random().toString(36).substring(2,9).toUpperCase(),
					paymentMethod:[],
					amountPaid:0,
					remark:'',
					paymentDate:''
				 })
				})   
				  
		}
		
		const handleChange =(event)=>{
			let {name, value} = event.target
			setStudentFee({...studentFee, [name]:value.replace(/[^0-9]/g, '')})
		}

	const tablePaymentHeader = [
		{dataField: 'code', text: 'Action', formatter:(cell, row)=><form method='POST' action={ServerUrl+'/printout/payment_receipt.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='slipcode' value ={cell} />
         <input type='hidden' name='jwtToken' value ={Token} />
		

		<button type='submit' className="btn btn-info btn-sm"  title='Print' > <i className="fa fa-print"></i> </button>
		</form>},
		{dataField: 'receiptNo', text: 'Receipt No'},
		{dataField: 'fees', text: 'Fees'},
		{dataField: 'status', text: 'Status',   formatter: (cell)=>cell==='PAID'?<span className="pcoded-badge label label-success">{cell}</span>:cell==='PARTIAL PAYMENT'? <span className="pcoded-badge label label-warning">{cell}</span>:<span className="pcoded-badge label label-danger">{cell}</span>},
		{dataField: 'paymentMode', text: 'Mode'},
		{dataField: 'amount', text: 'Amount ('+Currency+')',  formatter:(cell)=>FormatNumber(cell)},
		{dataField: 'remark', text: 'Remark', formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		{dataField: 'datePaid', text: 'Date Paid', formatter:(cell)=>longDate(cell)}
		
	 ];

	

	 const TablePayment=(props)=>{
	   
	const { SearchBar } = Search;
	const CustomToggleList = ({
		columns,
		onColumnToggle,
		toggles
	  }) => ( 
	  <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
		  {
			columns
			  .map(column => ({
				...column,
				toggle: toggles[column.dataField]
			  }))
			  .map(column => (
				
				<button
				  type="button"
				  key={ column.dataField }
				  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
				  data-toggle="button"
				  aria-pressed={ column.toggle ? 'true' : 'false' }
				  onClick={ () => onColumnToggle(column.dataField) }
				>
				  { column.text }
				</button>
			  ))
		  }
		</div>
		
	  );
	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='code' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
						<div className="form-group">

					<SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								
		<div className="btn-group dropdown-split-success">
			
			<button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i className="fa fa-search-plus"></i> Columns
			</button>
			
			<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
			<CustomToggleList { ...props.columnToggleProps } />
			</div>
			</div>
									
			<div className="dt-buttons btn-group pull-right"> 
				<form method='POST' action={ServerUrl+'/printout/student_payments_history.php?token='+userToken} target='_blank'>
		
				<input type='hidden' name='studentCode' value ={userCode} />
				<input type='hidden' name='jwtToken' value ={Token} />

				<button type="submit" className="btn btn-outline-primary "  > <i className="fa fa-eye"></i>Print Preview
		</button>
		</form>
		
	</div>                   
	</div>

	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
					}
</ToolkitProvider>
}


  
const paystackConfig = {
reference: txRef,
email: student.fatherEmail!==''?student.fatherEmai:student.motherEmail,
amount: amountTopay,
publicKey: PSKLIVE 
};

const onSuccess = (ref) => {
	submitPayment(ref.transaction)
};

const onClose = () => {
// implementation for  whatever you want to do when the Paystack dialog closed.
//console.log('closed')
}

const handlePaystackPayment = usePaystackPayment(paystackConfig);

		 useEffect(()=>{
			fetchFees()
			fetchStudent() 
	fetchPayment()
	handleFetchFeeType()
		},[]);

        return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Fees Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
							
		
	
	
{fees.length!==0?
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Fees List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


				<div className="card z-depth-0">
				<div className="card-block">
		
				<div className="row">
				<div className="col-md-12 table-responsive"> 
				
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Fees</th>
						
						<th> Due Amount ({Currency})</th>
						<th>Paid Amount ({Currency})</th>
						<th> Balance ({Currency})</th>
						<th> Action</th>
					</tr>
				</thead>
				<tbody >
					{fees.length!==0?fees.map((dat, id)=>{
					return	<tr key={id}>
					<td>{dat.fees}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					<td>

						<form method='POST' action={ServerUrl+'/printout/student_class_fees.php?token='+userToken} target='_blank'>
		<input type='hidden' name='studentCode' value ={userCode} />
		<input type='hidden' name='jwtToken' value ={Token} />
		<input type='hidden' name='feescharge' value ={dat.fees} />

		<input type='hidden' name='feeCharge' value ={dat.feeCharge} />
		<input type='hidden' name='feeCode' value ={dat.code} />

		{Number(dat.paid) < Number(dat.total)?<div><button type='button' onClick={()=>fetchFeestructure(dat)} className="btn btn-success btn-sm ml-2"> <i className="fa fa-credit-card"></i> Pay Now</button> 
		
 </div>:''}

		
 {Number(dat.paid) === Number(dat.total)?<button type="submit" className="btn btn-inverse btn-sm ml-2"><i className='fa fa-print'></i>Print Invoice</button>:[]}

</form>			</td>
						</tr>
					}):[]}
				</tbody>
			</table>
			
			</div></div>
				
		</div></div>

{feeStructure.structure.length!==0?


		<div className="card z-depth-0">
				<div className="card-block">
		
				<div className="row">
				<div className="col-md-12 table-responsive"> 
				



				
{ Number(feeStructure.school_fee)>0?
<table className="table table-bordered">
				<thead>
					<tr>
						<th>Fees Charge</th>
						<th>School Fee ({Currency})</th>
						<th>Tuition Fee ({Currency})</th>
						<th>Discount ({Currency})</th>
						<th>To Pay ({Currency})</th>
					</tr>
				</thead>
				<tbody >
			<tr >
						<td>{feeStructure.fees}</td>
						<td>{FormatNumber(feeStructure.school_fee)}</td>
						<td>{FormatNumber(feeStructure.tuition_fee)}</td>
						<td>{FormatNumber(feeStructure.discountAmount)}</td>
						<td>{FormatNumber(feeStructure.total)}</td>
					</tr>
				</tbody>
				
				<tfoot>
			<tr>
				<th className='text-right' colSpan={4}>Total</th>
				<th>{FormatNumber(feeStructure.total)}</th>
			</tr>
			<tr>
				<th className='text-right' colSpan={4}>Amount to Pay</th>
				<th >{FormatNumber(feeStructure.balance)}</th>
			</tr>
		</tfoot>

			</table>:
			<table className="table table-bordered">
			<thead>
					<tr>
						<th>Fees Type</th>
						<th>Amount ({Currency})</th>
						<th>Discount ({Currency})</th>
						<th>To Pay ({Currency})</th>
					</tr>
				</thead>
				<tbody >
			{feeStructure.structure.map((fee, idx)=> {
                return  Number(fee.price)>0? <tr key={idx}>
						<td>{getFees(fee.ID)}</td>
						<td>{FormatNumber(fee.price)}</td>
						<td>{FormatNumber(fee.discountAmount)}</td>
						<td>{FormatNumber(fee.discount)}</td>
					</tr>:[]
				})}
				</tbody>
				
				<tfoot>
					<tr>
						<th className='text-right' colSpan={3}>Total</th>
						<th>{FormatNumber(feeStructure.total)}</th>
					</tr>
					<tr>
						<th className='text-right' colSpan={3}>Amount to Pay</th>
						<th >{FormatNumber(feeStructure.balance)}</th>
					</tr>
					<tr>
				<th className='text-right' colSpan={3}>Installment Amount</th>
				<th ><input type='text' name="installment" className='form-control' pattern='[0-9]' onChange={handleChange} value={studentFee.installment} /></th>
			</tr>
				</tfoot>
			</table>}
			
			</div></div>
				
		</div></div>:''}



		<div ref={structure} />

		<hr/>

		{feeStructure.structure.length!==0?
<footer className="">
<form method='POST' action={ServerUrl+'/printout/student_fees_voucher.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='studentCode' value ={userCode} />
		<input type='hidden' name='jwtToken' value ={Token} />
		<input type='hidden' name='feecharge' value ={feeStructure.feeCharge} />
		<button type="submit" id="submit" className="btn btn-inverse pull-left" > <i className="fa fa-bank"></i> Pay In Bank</button>
		
</form>

&nbsp;

<button type="button" id="button" className="btn btn-success " onClick={() =>  handlePaystackPayment(onSuccess, onClose)
            }><i className="fa fa-credit-card"></i> Pay Online Now {Currency+FormatNumber(amountTopay/100)}</button>
</footer>:''}
            </div>
            </div> 
                </div>:''}


                </div>

{payment.length!==0?
				<div className="row">

				<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Paid List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
	
        <TablePayment data={payment}  columns={tablePaymentHeader} />
                </div>
            </div>
            </div> 
                </div></div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(FeesPayment) 