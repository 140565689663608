import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency,  config, familyCode, PSKLIVE, API_URL} from '../component/include'

import { usePaystackPayment } from 'react-paystack';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, FormatNumber, extract_mail } from '../component/globalFunction'
import { userToken } from '../component/authentication'


const ExtraPayment=()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
  
const [studentList, setStudentList] = useState([]);
const [errors, setErrors] = useState({});
const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
	student:[],
	remark:'',
	email:'',
});
const [allFees, setAllFees]= useState([])



const fetchStudents =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT admissionNumber, registerNumber, studentName, classID, sectionID, code, fatherName, father_mobile, motherEmail, fatherEmail, email from tbl_students where family_name ='"+familyCode+"'"

	const fd = new FormData();
	fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    
setStudentList(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}




const fetchPayment =(code)=>{
	setNotice({...notice, isLoading: true})  

	let url = API_URL+'/fees/student_payment/'+code;
	axios.get(url,  config).then(result=>{
		if(result.data.fees.length!==0){
			setAllFees(result.data.fees)
		}else{
			setAllFees([])
		}
		if(result.data.payment.length!==0){
			setPayment(result.data.payment)

			window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
		}else{
			setPayment([])
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, isLoading: false})  
	})
	}

const handleSelect = (option, action)=>{

	let email = extract_mail(option.fatherEmail!==''?option.fatherEmail:option.motherEmail!==''?option.motherEmail:option.email)
	

	setStudentFee({...studentFee, [action.name]: option, email:email[0]});
	setErrors({...errors, [action.name]:''})
	fetchPayment(option.value)
 }

 const handleChange = event =>{
	let {name, value} = event.target
  setStudentFee({...studentFee, [name]:value})
  setErrors({...errors, [name]:''})
}







const handleSelected =(data, status) =>{

	let subscribe =  payment.filter(item=>item.extra_code===data.code )

	if(subscribe.length===0){
	let list = allFees.map((item)=>{
		if(item.code ===data.code){
			return {...item, selected:status}
		}
		return item
	})
setAllFees(list)
setStudentFee({...studentFee, pay_now:false})
	}else{

		Alerts('Error!', 'danger', 'Student already registered for the selected item ')
	}
}

		 
	function submit(reference){  
    
			const fd = new FormData();

			var options = allFees.filter((items)=>items.selected==='Yes')
			var value = [];
			for (var i = 0, l = (options || []).length; i < l; i++) {
			  value.push(
				{
					extra_code:options[i].code,
					amount:options[i].amount
				
				});
			}
			var today = new Date().toISOString().slice(0,10);

			fd.append('student_code', studentFee.student.value);
			fd.append("extras", JSON.stringify(value, null, 2))
			fd.append('reference', reference);
			fd.append('payment_method', 'Paystack');
			fd.append('date_paid', today);
			fd.append('remark', studentFee.remark);
			setNotice({...notice,  isLoading: true}) 
		
				 let url = API_URL+'/extras/fee/payment'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.status==200) {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', response.data.message)
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', error.message) 
				})
				.finally(()=>{
					fetchPayment(studentFee.student.value)
				  setNotice({...notice, isLoading: false})  
				 setStudentFee({...studentFee,
					receiptNo:'',
					pay_now:false,
					paymentMethod:[],
					amountPaid:0,
					remark:'',
					paymentDate:''
				 })
				})   
				  
		}




	const tablePaymentHeader = [
		
		{dataField: 'code', text: 'Action', formatter:(cell, row)=><form method='POST' action={ServerUrl+'/printout/payment_receipt_extra.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='reference' value ={row.reference} />
         <input type='hidden' name='jwtToken' value ={Token} />
		 
		<button type='submit' className="btn btn-info btn-sm"  title='Print' > <i className="fa fa-print"></i> </button>
		

		</form>},
		{dataField: 'reference', text: 'Receipt No'},
		{dataField: 'term_name', text: 'Term'},
		{dataField: 'title', text: 'Title'},
		{dataField: 'payment_mode', text: 'Mode'}, 
		{dataField: 'amount', text: 'Amount ('+Currency+')',  formatter:(cell)=>FormatNumber(cell)},
		{dataField: 'remark', text: 'Remark', formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		{dataField: 'date_paid', text: 'Date Paid', formatter:(cell)=>longDate(cell)}
		
	 ];

	

const TablePayment=(props)=>{
	   
	const { SearchBar } = Search;
	const CustomToggleList = ({
		columns,
		onColumnToggle,
		toggles
	  }) => ( 
	  <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
		  {
			columns
			  .map(column => ({
				...column,
				toggle: toggles[column.dataField]
			  }))
			  .map(column => (
				
				<button
				  type="button"
				  key={ column.dataField }
				  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
				  data-toggle="button"
				  aria-pressed={ column.toggle ? 'true' : 'false' }
				  onClick={ () => onColumnToggle(column.dataField) }
				>
				  { column.text }
				</button>
			  ))
		  }
		</div>
		
	  );
	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='code' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
						<div className="form-group">

					<SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								

		<div className="btn-group dropdown-split-success">
			
			<button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i className="fa fa-search-plus"></i> Columns
			</button>
			
			<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
			<CustomToggleList { ...props.columnToggleProps } />
			</div>
			</div>
									
				<div className="dt-buttons btn-group pull-right"> 
				<form method='POST' action={ServerUrl+'/printout/student_payments_history_extra.php?token='+userToken} target='_blank'>

				<input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
				<input type='hidden' name='studentCode' value ={studentFee.student.value} />
				<input type='hidden' name='jwtToken' value ={Token} />

				<button type="submit" className="btn btn-outline-primary "  > <i className="fa fa-eye"></i>Print Preview
		</button>
		</form>
		
	</div>                   
	</div>

	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
					}
</ToolkitProvider>
}


const handlePaystackFees =()=>{
	let total  = allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0)

	var amount = 0
	var amountToCharge = (0.015 * total)
if(Number(total) <2500){

	amount =  (Number(total)+ (amountToCharge)) * 100

}else if(amountToCharge>2000){
		amount = (Number(total)+ (2000)) * 100
	}else{
		
	amount =  (Number(total)+ (amountToCharge+100)) * 100
	}
	return amount;
}


var amountTopay = handlePaystackFees()
var txRef = (new Date()).getTime().toString();

const paystackConfig = {
	reference: txRef,
	email: studentFee.email,
	amount: amountTopay,
	publicKey: PSKLIVE 
	};

	const onSuccess = (ref) => {
		handlePaymentReport(ref)
		submit(ref.transaction)
	};

	const onClose = () => {
		//console.log('closed')
		} 
		
	const handlePaystackPayment = usePaystackPayment(paystackConfig);


	const handlePaymentReport=(result)=>{
		let total  = allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0)

		const fd = new FormData();
		fd.append('jwt', Token);
		
		fd.append('amount', total);
		fd.append('currency', 'NGN');
		fd.append('flw_ref', JSON.stringify(result, null, 2));
		fd.append('transaction_id ', result.trans);
		fd.append('status', result.status);
		fd.append('tx_ref', txRef);
		
		fd.append('remark', studentFee.remark);
		fd.append('customers', JSON.stringify(studentFee.student, null,2));
		fd.append('code', Math.random().toString(36).substring(2,9));
			 let url = ServerUrl+'/save_controller/tbl_online_payment_report'
			axios.post(url, fd, config)
			.then(response =>{
				 if (response.data.type ==='success') {
					//Alerts('Saved!', 'success', response.data.message)
				}  else{
					Alerts('Error!', 'danger', JSON.stringify(response.data))
			}   
					
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', JSON.stringify(error.message)) 
			})
}


		 useEffect(()=>{
			fetchStudents();
		},[]);

        return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Extra-Curricular Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Extra-Curricular</a> </li>
                    <li className="breadcrumb-item"><a href="#!"> Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-graduation-cap" ></i> LEARNERS</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
										<div className="row">
										<section className="col-md-12">
									

			<div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, email:list.email, studentName:list.studentName, fatherEmail:list.fatherEmail, motherEmail:list.motherEmail }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName+' (Mail: '+option.fatherEmail+'   '+option.motherEmail +")"}
onChange={handleSelect}  name="student" value={studentFee.student}  /> 
											</div>
										</section>
									
									</div>
										
							</div>
							</div>

							</div>
		
	
	
{allFees &&allFees.length!==0?
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>CHOOSE EXTRA-CURRICULAR LIST</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
				<div className="card z-depth-0">
				<div className="card-block">
		
				<div className="row">
				<div className="col-md-12 table-responsive"> 
				
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>TERM</th>
						
						<th> TITLE </th>
						<th>AMOUNT ({Currency})</th>
						<th> ACTION</th>
					</tr>
				</thead>
				<tbody >
					{allFees.length!==0?allFees.map((dat, id)=>{
					return	<tr  onClick={()=>handleSelected(dat, dat.selected==='Yes'?'No':'Yes')} className={` pointer  ${dat.selected==='Yes'?'bg-primary':''} `} key={id}>
						<td>{dat.term_name}</td>
					<td><b>{dat.title}</b> <br/> <br/>Only For {"=>"} <span className='text-bold text-danger'> {dat.sections!=='[]'?JSON.parse(dat.sections).map(item=>item.sectionID+', '):''}</span></td>
					
					<td>{FormatNumber(dat.amount)}</td>

					<td>
						{dat.selected==='No'?
					<button type="button" onClick={()=>handleSelected(dat, 'Yes')} className="btn btn-inverse btn-sm ml-2"><i className='fa fa-plus-circle'></i>Select</button>:<button onClick={()=>handleSelected(dat, 'No')} type="button" className="btn btn-warning btn-sm ml-2"><i className='fa fa-minus-circle'></i>Remove</button>}

					
					</td>
						</tr>
					}):[]}
				</tbody>

				<tfoot>
				<tr>
				<th className='text-right text-bold' colSpan={3}>Total</th>
				<th >{FormatNumber(allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0))}</th>
			</tr>
			<tr>
				<th className='text-right text-bold' colSpan={3}>Service Charge</th>
				<th >{FormatNumber((amountTopay/100) - (allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0)) )}</th>
			</tr>
			<tr>
				<th className='text-right text-bold' colSpan={3}>Amount to Pay</th>
				<th >{FormatNumber(amountTopay/100)}</th>
			</tr>


<tr>
	<td colSpan={4}>
		
		<div className="form-group">
         <label>Enter Remark </label>

		 <textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChange} rows="2" >{studentFee.remark}</textarea>
		  
            </div></td>
</tr>
			{allFees.filter((items)=>items.selected==='Yes').length>0?<tr>
				<td className='text-right ' colSpan={4}><button  onClick={()=>handlePaystackPayment(onSuccess, onClose)
            }type="button" className="btn btn-outline-primary btn-block "><i className='fa fa-money'></i>Pay Online Now {Currency+FormatNumber(amountTopay/100)}</button></td>
			</tr>:[]}
		</tfoot>

			</table>
			
			</div></div>
				
		</div></div>


		

            </div>
            </div> 
                </div>:[]} 


                </div>

{payment.length!==0?
				<div className="row">

				<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Paid List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
	
        <TablePayment data={payment}  columns={tablePaymentHeader} />
                </div>
            </div>
            </div> 
                </div></div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExtraPayment) 